import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { Axios_API, Axios_No_Auth_API } from "../api";
import { ToastModule } from "./toast.store.module";
import { DebugModule } from "./debug.store.module";

const domainName = `${process.env.VUE_APP_DOMAIN}/api`;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    isLogin: false,
    loadingData: false,
    metaLoading: false,
    myDataset: [],
    Dataset: [],
    directoryTree: [],
    metadata: [],
    tags: [],
    selected: null,
    directoryRetive: [],
    currentPath: [],
    activeVuex: [],
    fileExplorer: [],
    deleteStatus: null,
    uploadQueue: [],
    dataDictionary: [],
    downloadStatus: "",
    connectionDatabase: {},
    profiling: {},
    fileStatus: null,
    uploadProgress: 0,
    fileNameupload: "",
    data_type: [],
    editDictStatus: "",
    permissionFile: {},
    peopleList: [],
    accountResults: [],
    configTheme: {},
    createTag: {},
    lineageData: {},
    activity_logs: [],
    createStatus: null,
    patchPermissionStatus: null,
    file_Viewer: null,
  },
  getters: {
    getIsLogin: (state) => state.isLogin,
    getLineageData: (state) => state.lineageData,
    getActivityLogs: (state) => state.activity_logs,
    // getPeopleList: (state) =>
    //   state.peopleList.filter(
    //     (value, index, self) => self.indexOf(value) === index
    //   ),
  },
  // TODO: Mutations
  mutations: {
    SET_USER(state, payload) {
      const splitName = payload.user.split("-");
      let initials = "";
      if (splitName.length > 1) {
        const firstname = splitName[0].charAt(0).toUpperCase();
        const lastname = splitName[1].charAt(0).toUpperCase();
        initials = firstname + lastname;
      } else {
        initials = splitName[0].charAt(0).toUpperCase();
      }

      state.user = {
        initials: initials,
        fullName: payload.user,
        email: payload.email,
      };
    },
    SET_ACTIVITY_LOGS(state, payload) {
      state.activity_logs = payload;
    },
    SET_LOGIN(state) {
      state.isLogin = true;
    },
    SET_LOGOUT(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      state.isLogin = false;
    },
    GET_MYDATASET(state, payload) {
      console.log("daset", payload);

      state.myDataset = payload.map((i) => {
        return {
          ...i,
          id: i.directory.id,
          dataset_id: i.id,
          directory: { ...i.directory, dataset_id: i.id },
          type: "dataset",
          size: 20,
        };
      });
    },
    MUTATION_METADATA(state, payload) {
      console.log("mutation metadata:", state, "|", payload);
      state.metadata = payload;
    },
    GET_TAG(state, payload) {
      state.tags = payload;
    },
    GET_TREE(state, payload) {
      state.directoryTree = payload;
    },
    SET_SELECTED(state, payload) {
      state.selected = payload;
    },
    SET_DIRECTORY(state, payload) {
      console.log("Mutation", payload);

      const directorys = payload.data?.directorys
        ? payload.data.directorys.map((i) => {
            return {
              id: i.id,
              name: i.name,
              [i?.dataset_id ? "dataset_id" : null]: i.dataset_id,
              description: i.description,
              create_at: i.create_at,
              last_modified: i.last_modified,
              size: i.size,
              owner: i.owner.user,
              type: i.parent_dir === null ? "dataset" : "folder",
              human_size: i.human_size,
            };
          })
        : [];

      const Files = payload.data?.files
        ? payload.data.files.map((i) => {
            return {
              id: i.id,
              name: i.name,
              description: i.description,
              create_at: i.create_at,
              last_modified: i.last_modified,
              size: i.size,
              owner: i.owner.user,
              human_size: i.human_size,
              type: "file",
            };
          })
        : [];

      let path = [];
      if (payload.data.id != 0) {
        const recursive = (data) => {
          payload.parent_dir === null
            ? path.push({
                id: 0,
                text: "My Dataset",
                disabled: false,
              })
            : Object.keys(data).length === 0
            ? path.push({
                id: 0,
                text: "My Dataset",
                disabled: false,
              })
            : Object.entries(data).reduce((i, [key, value]) => {
                console.log(`item`, i);
                console.log("key", key);
                console.log("value", value);
                if (key === "parent" && Object.keys(value).length > 0) {
                  recursive(value);
                  path.push({
                    id: i[1].id,
                    text: i[1].name,
                    type: i[1].parent_dir === null ? "dataset" : "folder",
                    disabled: false,
                    href: "",
                  });
                } else {
                  path.push({
                    id: 0,
                    text: "My Dataset",
                    disabled: false,
                  });
                  path.push({
                    id: i[1].id,
                    text: i[1].name,
                    type: i[1].parent_dir === null ? "dataset" : "folder",

                    disabled: false,
                    href: "",
                  });
                }
              });
        };
        recursive(payload.data.parent);
      }
      path.push({
        id: payload.data.id,
        text: payload.data.name,
        disabled: true,
      });

      let sharePath = [{ id: 99, text: "Shared", disabled: false }];
      if (payload.path === "sharewithme") {
        sharePath.push({
          id: payload.data.id,
          text: payload.data.name,
          disabled: true,
        });
      }
      state.activeVuex = payload.id;
      state.currentPath = payload.path === "sharewithme" ? sharePath : path;
      // if (payload.path === "sharewithme") {
      //   state.currentPath = sharePath;
      // } else {

      // }

      state.selected = payload;
      state.directoryRetive = directorys.concat(Files);
    },
    SET_EXPLORER(state, payload) {
      state.fileExplorer = payload;
    },
    SET_DELETESTATUS(state, payload) {
      state.deleteStatus = payload;
    },
    UPDATE_MY_DATASET(state, payload) {
      const oldArray = state.myDataset;
      const oldDir = state.directoryRetive;
      const { directory } = payload;
      const newDataset = {
        dataset_id: payload.id,
        id: directory.id,
        name: payload.name,
        title: payload.title,
        description: directory.description,
        create_at: directory.create_at,
        last_modified: directory.last_modified,
        owner: payload.owner.user,
        tag: payload.tag,
        type: "dataset",
      };
      const newArray = [newDataset, ...oldArray];
      const newDirArr = [newDataset, ...oldDir];
      state.myDataset = newArray;
      state.directoryRetive = newDirArr;
      state.selected = newDataset;
    },
    SET_DATADICTIONARY(state, payload) {
      state.dataDictionary = payload;
    },
    SET_DOWNLOADSTAT(state, payload) {
      state.downloadStatus = payload;
    },
    SET_CONNECTION(state, payload) {
      state.connectionDatabase = payload;
    },
    SET_PROFILING(state, payload) {
      state.profiling = payload;
    },
    SET_STATUSUPLOAD(state, payload) {
      state.fileStatus = payload;
    },
    SET_FILE_STATUS(state, payload) {
      state.fileStatus = payload;
    },
    SET_UPLOADPROGRESS(state, payload) {
      state.uploadProgress = payload;
    },
    SET_UPLOADNAME(state, payload) {
      state.fileNameupload = payload;
    },
    SET_DATATYPE(state, payload) {
      state.data_type = payload;
    },
    SET_EDITDICTIONARY(state, payload) {
      state.editDictStatus = payload;
    },
    SET_TAG(state, payload) {
      state.createTag = payload;
    },
    SET_LOADING(state, payload) {
      state.loadingData = payload;
    },
    SET_METALOADING(state, payload) {
      state.metaLoading = payload;
    },
    SET_FILE_PERMISSION(state, payload) {
      let people = [];

      Object.entries(payload).reduce((i, [k, n]) => {
        console.log("wtf", i, k, n);
        if (k === "owner") {
          people.push({
            id: n.id,
            user: n.user,
            email: n.email,
            department: n.department,
            role: "owner",
          });
        } else if (k === "viewer") {
          n.forEach((i) => {
            people.push({
              id: i.id,
              user: i.user,
              email: i.email,
              department: i.department,
              role: "viewer",
            });
          });
        } else if (k === "editor") {
          n.forEach((i) => {
            people.push({
              id: i.id,
              user: i.user,
              email: i.email,
              department: i.department,
              role: "editor",
            });
          });
        }
      });
      console.log("peopleList :", people);
      state.peopleList = people;
      state.permissionFile = payload;
    },
    GET_ACCOUNT_RESULT(state, payload) {
      state.accountResults = payload.results;
    },
    ADD_PEOPLE(state, payload) {
      state.peopleList.push({
        id: payload.id,
        user: payload.user,
        email: payload.email,
        department: payload.department,
        role: "viewer",
      });
    },
    SET_SHARED(state, payload) {
      state.currentPath = [];
      const datasets = payload?.dataset
        ? payload.dataset.map((i) => {
            return {
              id: i.directory.id,
              [i?.directory ? "dataset_id" : ""]: i.id,
              name: i.directory.name,
              description: i.directory.description,
              create_at: i.directory.create_at,
              last_modified: i.directory.last_modified,
              size: i.directory.size,
              owner: i.directory.owner.user,
              type: i.directory.parent_dir === null ? "dataset" : "folder",
              human_size: i.directory.human_size,
            };
          })
        : [];
      const directorys = payload?.directorys
        ? payload.directorys.map((i) => {
            return {
              id: i.id,
              name: i.name,
              description: i.description,
              create_at: i.create_at,
              last_modified: i.last_modified,
              size: i.size,
              owner: i.owner.user,
              type: i.parent_dir === null ? "dataset" : "folder",
              human_size: i.human_size,
            };
          })
        : [];

      const Files = payload?.files
        ? payload.files.map((i) => {
            return {
              id: i.id,
              name: i.name,
              description: i.description,
              create_at: i.create_at,
              last_modified: i.last_modified,
              size: i.size,
              owner: i.owner.user,
              human_size: i.human_size,
              type: "file",
            };
          })
        : [];

      let path = [];

      path.push({
        id: 99,
        text: "Shared",
        disabled: true,
      });
      state.currentPath = path;
      state.directoryRetive = datasets.concat(directorys, Files);
    },
    SET_CONFIG(state, payload) {
      state.configTheme = payload;
    },
    SET_LINEAGEDATA(state, payload) {
      state.lineageData = payload;
    },
    SET_CREATESTATUS(state, payload) {
      state.createStatus = payload;
    },
    SET_PERMISSTION(state, payload) {
      state.patchPermissionStatus = payload;
    },
    SET_FILEVIEWER(state, payload) {
      state.file_Viewer = payload;
    },
  },
  // TODO: add actions
  actions: {
    getMydataset({ commit }) {
      Axios_API.get(`/v2/dataset/me/`).then((res) =>
        commit("GET_MYDATASET", res.data)
      );
    },
    async getDataset(_, { id }) {
      const { data, status } = await Axios_API.get(`/v2/dataset/${id}/`);
      if (status === 200) {
        return data;
      }
      return [];
    },
    getDirectory({ commit }, params) {
      console.log("directoryGet", params);
      commit("SET_LOADING", true);

      let addPath = {
        data: {
          id: 0,
          name: "My Dataset",
          description: "My Dataset",
          is_use: true,
          parent_dir: null,
          directorys: this.state.myDataset.map((i) => i.directory),
        },
        path: params.path,
      };
      params.id == 0
        ? commit("SET_DIRECTORY", addPath)
        : params.id == 99 || params == undefined
        ? this.dispatch("getShared")
        : Axios_API.get(`/v2/directory/${params.id}/`)
            .then((res) => {
              let claimPath = {
                data: res.data,
                path: params.path,
              };
              commit("SET_DIRECTORY", claimPath);
            })
            .catch((err) => {
              console.log("M2-error", err);
            });
    },
    getMetadataByID({ commit }, { type, id, route }) {
      commit("SET_METALOADING", true);
      console.log("instance:", type, "|", id);
      if (id !== undefined) {
        switch (type) {
          case 0: {
            // Dataset
            console.log("Get dataset");
            Axios_API.get(`/v2/dataset/${id}/`)
              .then((response) => {
                console.log("dataset res:", response);
                const { name, title, owner, tag, directory, readme } =
                  response.data;
                const result = {
                  type: 0,
                  id: id,
                  metadata: {
                    name,
                    title,
                    directoryId: directory.id,
                    description: directory.description,
                    create_at: directory.create_at,
                    last_modified: directory.last_modified,
                    owner: {
                      user: owner.user,
                      email: owner.email,
                    },
                    tag,
                  },
                  activity: [],
                  readme,
                };
                console.log("result:", result);
                commit("MUTATION_METADATA", result);
              })
              .catch((error) => console.error("Error getMetadataByID:", error));
            break;
          }
          case 1: {
            // Folder
            Axios_API.get(`/v2/directory/${id}/`)
              .then((response) => {
                console.log("get id:", response);
                const {
                  name,
                  description,
                  create_at,
                  last_modified,
                  owner,
                  readme,
                } = response.data;
                const result = {
                  type: 1,
                  id,
                  metadata: {
                    name,
                    description,
                    create_at,
                    last_modified,
                    owner: {
                      user: owner.user,
                      email: owner.email,
                    },
                  },
                  activity: [],
                  readme,
                };
                commit("MUTATION_METADATA", result);
              })
              .catch((error) => console.error("Error getMetadataByID:", error));
            break;
          }
          case 2: {
            // File
            // GET Lineage'
            const routeNoLineage = ["fileviewer", "datadictionary"];
            if (!routeNoLineage.includes(route)) {
              Axios_API.get(`/v2/file/${id}/getLineage/`).then((res) =>
                commit("SET_LINEAGEDATA", res.data)
              );
            }
            // GET file metadata
            Axios_API.get(`/v2/file/${id}/`)
              .then((response) => {
                const {
                  name,
                  description,
                  create_at,
                  last_modified,
                  size,
                  human_size,
                  type,
                  owner,
                  // lineage,
                  directory,
                  path,
                  context,
                } = response.data;
                const result = {
                  type: 2,
                  id: id,
                  metadata: {
                    name,
                    description,
                    create_at,
                    last_modified,
                    size,
                    human_size,
                    type,
                    owner: {
                      user: owner.user,
                      email: owner.email,
                    },
                  },
                  activity: [],
                  // lineage: lineageData(),
                  directory,
                  path,
                  context,
                };
                console.log("result:", result);
                commit("MUTATION_METADATA", result);
              })
              .catch((error) => console.error("Error getMetadataByID:", error));
            break;
          }
          default: {
            // Not select item
            const example = {
              type: "default",
              id: -1,
              metadata: {
                name: "-",
                description: null,
                create_at: null,
                last_modified: null,
                size: 0,
                human_size: 0,
                type: "-",
                owner: {
                  user: "-",
                  email: "-",
                },
              },
              activity: [],
            };
            commit("MUTATION_METADATA", example);
            break;
          }
        }
      }
    },
    async patchMetadata({ dispatch }, { type, id, directoryId, data }) {
      console.log("cal path:", `${type}|${id} `, data, directoryId, id, type);
      switch (type) {
        case 0: {
          // Dataset
          if (data.description) {
            await Axios_API.patch(`/v2/directory/${directoryId}/`, data)
              .then((response) => {
                const { status, data } = response;
                if (status === 202) {
                  console.log("success:", data.msg);
                }
              })
              .catch((error) => {
                console.log("Patch dataset error:", error);
              });
          } else {
            await Axios_API.patch(`/v2/dataset/${id}/`, data)
              .then((response) => {
                const { status, data } = response;
                if (status === 202) {
                  console.log("success:", data.msg);
                }
              })
              .catch((error) => {
                console.log("Patch dataset error:", error);
              });
          }
          await dispatch("getMetadataByID", { type, id });
          break;
        }
        case 1: {
          // Folder
          console.log("patch folder with:", data);
          await Axios_API.patch(`/v2/directory/${id}/`, data)
            .then((response) => {
              const { status, data } = response;
              if (status === 202) {
                console.log("success:", data.msg);
              }
            })
            .catch((error) => {
              console.log("Patch folder error:", error);
            });
          await dispatch("getMetadataByID", { type, id });
          break;
        }
        case 2: {
          // File
          console.log("patch file with:", data);
          await Axios_API.patch(`/v2/file/${id}/`, data)
            .then((response) => {
              const { status, data } = response;
              if (status === 202) {
                console.log("success:", data.msg);
              }
            })
            .catch((error) => {
              console.log("Patch file error:", error);
            });
          await dispatch("getMetadataByID", { type, id });
          break;
        }
        default: {
          break;
        }
      }
    },
    async postCreate({ commit }, { type, data }) {
      console.log(`post ${type === 0 ? "dataset" : "folder"} :`, type, data);
      switch (type) {
        case 0: {
          // Dataset
          const result = await Axios_API.post(`/v2/dataset/`, data)
            .then((response) => {
              if (response.status === 201) {
                // const { dataset } = response.data;
                // commit("UPDATE_MY_DATASET", dataset);
                this.dispatch("getMydataset");
              }
              return response;
            })
            .catch((error) => {
              console.log("error", error.response.data.msg);
              return error.response;
            });
          commit("SET_CREATESTATUS", result.response);
          return result;
        }
        case 1: {
          // Folder
          const result = await Axios_API.post(`/v2/directory/`, data)
            .then((response) => {
              console.log(response);
              // const params = Number(data.parent_dir);
              // this.dispatch("getDirectory", params);
              commit("SET_CREATESTATUS", response);
              return response;
            })
            .catch((error) => {
              return error.response;
            });
          console.log("create result", result.response.data.msg);
          commit("SET_", result.response);
          return result;
        }
      }
    },
    checkFileExists({ commit }, payload) {
      console.log("tick", payload);
      Axios_API.get(
        `/v2/directory/${payload.id}/fileExists/?filename=${payload.name}`
      )
        .then((res) => {
          console.log("CheckFilesupload", res.status);
          commit("SET_STATUSUPLOAD", res.status);
        })
        .catch((err) => {
          console.log("uploaderror", err.response.status);
          commit("SET_STATUSUPLOAD", err.response.status);
        });
    },
    async postUploadFiles({ commit }, data) {
      console.log("postUpload", data);
      console.log("postUpload2", data.rawFile);
      commit("SET_UPLOADPROGRESS", 0);
      // for (var pair of data.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // console.log("get parent id:", data.get("directory"));

      const response = await Axios_API.post(`/v2/file/`, data.data, {
        "Content-Type": "multipart/form-data",
      });

      if (response) {
        axios
          .put(`${response.data.url}`, data.rawFile, {
            onUploadProgress: (progressEvent) => {
              var percentCompelete = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (onprogress) onprogress(percentCompelete);
              console.log("upload progress:", percentCompelete);
              commit("SET_UPLOADPROGRESS", percentCompelete);
              commit("SET_UPLOADNAME", data.data.get("name"));
              return percentCompelete;
            },
          })
          .then((res) => console.log("putres", res));
        const params = Number(data.data.get("directory"));
        console.log("routeName",data.routeName)
        console.log("routePath",data.routePath)
        let uploadPayload = {
          id: params,
          path: data.routeName,
        };
        this.dispatch("getDirectory", uploadPayload);
        return response;
      }
    },
    getTag({ commit }) {
      Axios_API.get(`/v2/tag/`).then((response) => {
        console.log("tag:", response.data);
        commit("GET_TAG", response.data);
      });
    },
    getTree({ commit }, params) {
      console.log("logVuex  ", this.state.myDataset);

      Axios_API.get(`/v2/directory/${params}/directory/`).then((res) =>
        commit(
          "GET_TREE",
          this.state.directoryTree.length != 0
            ? this.state.directoryTree.map((i) =>
                i.directory.id == res.data.map((i) => i.parent_dir)
                  ? {
                      ...i,
                      id: i.directory.id,
                      dataset_id: i.id,
                      child: res.data.map((i) => {
                        return {
                          ...i,
                          type: "folder",
                        };
                      }),
                    }
                  : i
              )
            : this.state.myDataset.map((i) =>
                i.directory.id == res.data.map((i) => i.parent_dir)
                  ? {
                      ...i,
                      id: i.directory.id,
                      dataset_id: i.id,
                      child: res.data.map((i) => {
                        return {
                          ...i,
                          type: "folder",
                        };
                      }),
                    }
                  : i
              )
        )
      );
    },

    deleteItem({ commit }, { type, id, dataset_id }) {
      commit("SET_LOADING", true);
      switch (type) {
        case "folder":
          Axios_API.delete(`/v2/directory/${id}/`).then((res) =>
            commit("SET_DELETESTATUS", res.status)
          );
          break;
        case "file":
          Axios_API.delete(`/v2/file/${id}/`).then((res) =>
            commit("SET_DELETESTATUS", res.status)
          );
          break;
        default:
          Axios_API.delete(`/v2/dataset/${dataset_id}/`).then((res) => {
            console.log("delete", res.data);
            commit("SET_DELETESTATUS", res.status);
            this.dispatch("getMydataset");
          });
          break;
      }
    },
    getDatadictionary({ commit }, params) {
      Axios_API.get(`/v2/file/${params}/getDatadict/`).then((res) => {
        console.log("getDatadictionary", res.data);
        commit(
          "SET_DATADICTIONARY",
          res.data.map((i) => {
            return {
              ...i,
              disabled: true,
              editable: true,
            };
          })
        );
      });
    },
    getUserProfile({ commit }) {
      Axios_API.get(`/v2/account/me/`).then((res) => {
        commit("SET_USER", res.data);
      });
    },
    async getSearch({ state }, { keyword }) {
      console.log("search:", keyword);
      if (state.isLogin) {
        return await Axios_API.get(`/v2/search/?q=${keyword}`);
      } else {
        return await Axios_No_Auth_API.get(`/v2/search/?q=${keyword}`);
      }
    },
    async getApproveRetive(_, payload) {
      console.log("getApproveRetive:", payload);
      switch (payload.type) {
        case "file":
          return await Axios_API.get(`/v2/file/${payload.id}/`);
        case "directory":
          return await Axios_API.get(`/v2/directory/${payload.id}/`);
        default:
          return await Axios_API.get(`/v2/dataset/${payload.id}/`);
      }
    },
    donwloadFile({ commit }, payload) {
      Axios_API.get(`/v2/file/${payload.id}/download/`).then((res) => {
        // download file
        // const link = document.createElement("a");
        // link.href = res.data.url;
        // link.setAttribute("download", payload.name);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        axios.get(`${res.data.url}`, { responseType: "blob" }).then((res) => {
          console.log("res", res);
          const blob = new Blob([res.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", payload.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });

        commit("SET_DOWNLOADSTAT", res);
      });
    },
    createConnection({ commit }, payload) {
      console.log("create", JSON.stringify(payload));
      const jsonSting = JSON.stringify(payload);
      const blob = new Blob([jsonSting], { type: "text/json" });
      const data = new FormData();

      data.append("file", blob, "connection.json");
      console.log("blob", blob);
      // for (var val of data.values()) {
      //   console.log("formData", val);
      // }
      commit("SET_CONNECTION", payload);
    },
    createDatadict({ commit }, params) {
      Axios_API.get(`/v2/file/${params}/createDatadict/`).then((res) => {
        console.log("createDatadict", res.data);
        commit("SET_DATADICT", res.data);
      });
    },
    createProfiling({ commit }, params) {
      Axios_API.get(`/v2/file/${params}/createProfiling/`).then((res) => {
        console.log("createProfiling", res.data);
        commit("SET_PROFILE", res.data);
      });
    },
    getProfiling({ commit }, params) {
      Axios_API.get(`/v2/file/${params}/getProfileling/`).then((res) => {
        commit("SET_PROFILING", res.data);
        // dispatch("getDatadictionary", params);
      });
    },
    getDataType({ commit }) {
      Axios_API.get(`/v2/data-type/`).then((res) => {
        commit("SET_DATATYPE", res.data);
      });
    },
    patchDatadictionary({ commit }, payload) {
      Axios_API.patch(`/v2/data-dictionary/${payload.id}/`, payload).then(
        (res) => {
          commit("SET_EDITDICTIONARY", res.data);
        }
      );
    },
    setFileStatus({ commit }) {
      commit("SET_FILE_STATUS", 0);
    },
    getfilePermissions({ commit }, payload) {
      console.log("type", payload);
      switch (payload.type) {
        case "file":
          Axios_API.get(`/v2/file/${payload.id}/getPermission/`).then((res) => {
            commit("SET_FILE_PERMISSION", res.data);
          });
          break;
        case "folder":
          Axios_API.get(`/v2/directory/${payload.id}/getPermission/`).then(
            (res) => {
              commit("SET_FILE_PERMISSION", res.data);
            }
          );
          break;
        case "dataset":
          Axios_API.get(`/v2/dataset/${payload.id}/getPermission/`).then(
            (res) => {
              commit("SET_FILE_PERMISSION", res.data);
            }
          );
          break;
      }

      // payload.type === "file"
      //   ? Axios_API.get(`/v2/file/${payload.id}/getPermission/`).then((res) => {
      //       commit("SET_FILE_PERMISSION", res.data);
      //     })
      //   : payload.type === "folder"
      //   ? Axios_API.get(`/v2/directory/${payload.id}/getPermission/`).then(
      //       (res) => {
      //         commit("SET_FILE_PERMISSION", res.data);
      //       }
      //     )
      //   : null;
    },
    searchAccounts({ commit }, params) {
      Axios_API.get(`/v2/account/?search=${params}`).then((res) => {
        commit("GET_ACCOUNT_RESULT", res.data);
      });
    },
    addPeople({ commit }, payload) {
      commit("ADD_PEOPLE", payload);
    },
    patchFilePermission({ commit }, payload) {
      switch (payload.type) {
        case "file":
          Axios_API.patch(
            `/v2/file/${payload.id}/setPermission/`,
            payload.data
          ).then((res) => {
            console.log("patchFilePermission", res.data);
            commit("SET_PERMISSTION", res.status);
            this.dispatch("getfilePermissions", payload);
          });
          break;
        case "folder":
          Axios_API.patch(
            `/v2/directory/${payload.id}/setPermission/`,
            payload.data
          ).then((res) => {
            console.log("patchFilePermission", res.data);
            commit("SET_PERMISSTION", res.status);
            this.dispatch("getfilePermissions", payload);
          });
          break;
        case "dataset":
          Axios_API.patch(
            `/v2/dataset/${payload.id}/setPermission/`,
            payload.data
          ).then((res) => {
            console.log("patchFilePermission", res.data);
            commit("SET_PERMISSTION", res.status);
            this.dispatch("getfilePermissions", payload);
          });
          break;
      }
      // payload.type === "file"
      //   ? Axios_API.patch(
      //       `/v2/file/${payload.file_id}/setPermission/`,
      //       payload.data
      //     ).then((res) => {
      //       console.log("patchFilePermission", res.data);
      //     })
      //   : payload.type === "folder"
      //   ? Axios_API.patch(
      //       `/v2/directory/${payload.file_id}/setPermission/`,
      //       payload.data
      //     ).then((res) => {
      //       console.log("patchFilePermission", res.data);
      //     })
      //   : null;
    },
    getShared({ commit }) {
      Axios_API.get(`/v2/share-with-me/`).then((res) => {
        commit("SET_SHARED", res.data);
      });
    },
    getConfig({ commit, state }) {
      if (Object.keys(state.configTheme).length === 0) {
        console.log("Get config");
        axios.get(`${domainName}/config/`).then((res) => {
          commit("SET_CONFIG", res.data);
        });
      }
    },
    createTag({ commit }, payload) {
      Axios_API.post(`/v2/tag/`, payload).then((res) => {
        console.log("createTag", res.data);
        commit("SET_TAG", res.data);
      });
    },
    callActivityLogs({ commit }, payload) {
      console.log("activvity", payload);
      switch (payload.type) {
        case "file":
          Axios_API.get(`/v2/file/${payload.id}/getLogs/`).then((res) => {
            commit("SET_ACTIVITY_LOGS", res.data);
          });
          break;
        case "folder":
          Axios_API.get(`/v2/directory/${payload.id}/getLogs/`).then((res) => {
            commit("SET_ACTIVITY_LOGS", res.data);
          });
          break;
        case "dataset":
          Axios_API.get(`/v2/dataset/${payload.id}/getLogs/`).then((res) => {
            commit("SET_ACTIVITY_LOGS", res.data);
          });
          break;
        default:
          break;
      }
    },
    async getShareWithLink(_, params) {
      return await Axios_API.get(
        `/v2/share-with-me/shareWithLink/?code=${params}`
      );
    },
    async getFileRetrieve(_, params) {
      return await Axios_API.get(`/v2/file/${params}/`);
    },
    getFileViewer({ commit }, params) {
      Axios_API.get(`/v2/file/${params}/download/`).then((res) => {
        axios.get(`${res.data.url}`, { responseType: "blob" }).then((res) => {
          commit("SET_FILEVIEWER", res.data);
        });
      });
    },
  },
  modules: {
    ToastModule,
    DebugModule,
  },
});
